<template>
  <v-layout>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card outlined>
        <v-card-text>
          <p class="text-h6 mb-0">Asistencia</p>
          <v-container>
            <v-form ref="formGradeSearch">
              <v-row class="d-flex flex-row">
                <v-col cols="12" sm="3" md="3" class="py-0">
                  <p class="mb-2">Semestre</p>
                  <v-autocomplete
                    v-model="formGroup.idSemester"
                    :items="semesters"
                    :filter="semesterFilter"
                    outlined
                    dense
                    item-text="nombreSemestre"
                    item-value="idSemestre"
                    label="Seleccione"
                    single-line
                    @change="findGroup"
                    :rules="semesterRules"
                    no-data-text="No hay semestres disponibles"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-0">
                  <p class="mb-2">Grupo</p>
                  <v-autocomplete
                    v-model="formGroup.idGroup"
                    :items="groups"
                    :filter="groupFilter"
                    outlined
                    dense
                    item-text="nombreGrupo"
                    item-value="idGrupo"
                    label="Seleccione"
                    single-line
                    :rules="groupRules"
                    @change="changeGroup"
                    no-data-text="No hay grupos disponibles"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-0">
                  <p class="mb-2">Mes</p>
                  <v-autocomplete
                    v-model="formGroup.month"
                    :items="months"
                    outlined
                    dense
                    item-text="text"
                    item-value="value"
                    label="Seleccione"
                    single-line
                    :rules="monthsRules"
                    @change="changeMonth"
                    no-data-text="No hay meses disponibles"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-0" align-self="center">
                  <v-btn
                    color="#1486bd"
                    dark
                    elevation="0"
                    :loading="loading"
                    @click="findStudents"
                  >
                    <span class="text-capitalize text-body">Buscar Grupo</span>
                  </v-btn>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-show="studentsAssistance.length > 0"
                        class="ml-3 mr-2"
                        color="primary"
                        fab
                        depressed
                        small
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="print"
                      >
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </template>
                    <span>Imprimir Reporte</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-show="studentsAssistance.length > 0"
                        class="ml-3 mr-2"
                        color="#1D6F42"
                        fab
                        depressed
                        small
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="downloadXlsx"
                      >
                        <v-icon>mdi-microsoft-excel</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar Excel</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      <v-container v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>
      <template v-if="students.length > 0">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="students"
          item-key="idGrupoUsuario"
          class="elevation-1 font-weight-light caption"
          fixed-header
        >
          <template v-slot:[`item.estado`]="{ item }">
            {{ item.estado ? "Activo" : "Inactivo" }}
          </template>
          <template v-slot:[`item.accion`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="grey"
                  class="mr-2"
                  @click.stop="viewGrades(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <span>Ver asistencia</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
      <v-dialog v-model="dialogGrades" max-width="750">
        <v-card>
          <v-card-title style="color: #1874bf"
            >Asistencia del estudiante
            <p class="black--text mb-0 ml-2">
              {{ `${studentData.nombres} ${studentData.apellidos}` }}
            </p>
            <v-spacer />
            <!--v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="studentGrade.length > 0"
                  class="ml-3 mr-2"
                  color="primary"
                  fab
                  depressed
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="printGrade"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Imprimir Boletin</span>
            </v-tooltip-->
          </v-card-title>
          <v-card-text>
            <v-container v-if="loadingGrades" class="d-flex justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-container>
            <div v-show="!loadingGrades">
              <v-col class="pa-0">
                <v-sheet height="64">
                  <v-toolbar flat>
                    <v-btn
                      outlined
                      class="mr-4"
                      color="grey darken-2"
                      @click="setToday"
                    >
                      Hoy
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon small> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="next">
                      <v-icon small> mdi-chevron-right </v-icon>
                    </v-btn>
                    <v-toolbar-title> {{ titleCalendar }} </v-toolbar-title>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                  <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :start="startDate"
                    type="month"
                    :weekdays="[1, 2, 3, 4, 5, 6]"
                    @change="changeCalendar"
                  ></v-calendar>
                </v-sheet>
              </v-col>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <DialogDownload :dialog="dialogDownload" />
      <!--ReportStudentGrade
        ref="reportGrade"
        @downloadStop="dialogDownloadStop"
        :group="group"
        :student="studentData"
        :grades="studentGrade"
        :assistance="attendanceStudent"
      /-->
      <ReportStudentsAsisstanceComponent
        ref="studentRef"
        :user="user"
        :headers="headersPrint"
        :studentsAssistance="studentsAssistance"
        :group="formGroup"
      />
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
//import ReportStudentGrade from "@/components/report/report-student-grade-component.vue";
import DialogDownload from "@/components/dialog/dialog-download-component.vue";
import { onMounted, ref, watch } from "vue";
import { useStore } from "@/store";
import { getStudentsAssistance } from "@/api";
import ReportStudentsAsisstanceComponent from "@/components/report/report-students-asisstance-component.vue";
import * as XLSX from "xlsx";
export default {
  components: {
    //ReportStudentGrade,
    DialogDownload,
    ReportStudentsAsisstanceComponent,
  },
  setup() {
    const store = useStore();
    const user = ref(store.state.user);
    const studentRef = ref(null);

    const dialog = ref(false);
    const dialogDelete = ref(false);
    const search = ref("");
    const group = ref({});
    const dialogGrades = ref(false);
    const studentGrade = ref([]);
    const headersGrade = ref([]);
    const loadingGrades = ref(false);
    const dialogDownload = ref(false);
    const studentData = ref({});
    const headers = ref([
      {
        text: "Ci",
        value: "ci",
        align: "left",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Apellidos",
        value: "apellidos",
        align: "left",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Nombres",
        value: "nombres",
        align: "left",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Telefono",
        value: "telefono",
        align: "center",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Acciones",
        value: "accion",
        align: "center",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
    ]);
    const headersPrint = ref([]);
    const students = ref([]);
    const semesters = ref([]);
    const groups = ref([]);
    const months = ref([]);
    const formGroup = ref({
      idGroup: "",
      idSemester: "",
      idSubject: "",
      startDate: null,
      endDate: null,
      month: "",
      year: "",
      dateString: "",
    });
    const formGradeSearch = ref(null);
    const menuStart = ref(false);
    const menuEnd = ref(false);
    const activePicker = ref(null);
    const semesterRules = ref([(value) => !!value || "Seleccione un semestre"]);
    const groupRules = ref([(value) => !!value || "Seleccione un grupo"]);
    const monthsRules = ref([(value) => !!value || "Seleccione un mes"]);
    const loading = ref(false);
    const loadingData = ref(false);
    const snackbar = ref(false);
    const colorSnackbar = ref("");
    const textSnackbar = ref("");
    const editedItem = ref({});
    const items = ref([
      {
        text: "Asistencia",
        disabled: true,
        href: "/student-attendance",
      },
    ]);
    const turns = ref([]);
    const formGrade = ref({
      idGroup: "",
      idStudent: "",
      idTeacher: "",
      idSubject: "",
      dateIngress: "",
      partial: "",
      grade: "",
    });

    const drills = ref(0);
    const attendanceStudent = ref({});
    const studentsAssistance = ref([]);
    //calendar
    const focus = ref("");
    const events = ref([]);
    const titleCalendar = ref("");
    const calendar = ref(null);
    const startDate = ref(null);
    const endDate = ref(null);
    const isOpened = ref(false);
    const now = ref(null);

    //API methods
    const selectStudentsAssistance = async (params) => {
      try {
        let response = await getStudentsAssistance(params);
        if (response.status == 200) {
          response.data.students.map((val, index) => {
            val.nro = index + 1;
          });

          headersPrint.value.push(
            {
              text: "Nro",
              value: "nro",
              align: "left",
              sortable: false,
              class: "font-weight-bold blue-grey lighten-5",
            },
            {
              text: "Estudiante",
              value: "nombres",
              align: "center",
              sortable: false,
              class: "font-weight-bold blue-grey lighten-5",
            },
            {
              text: "CI",
              value: "ci",
              align: "center",
              sortable: false,
              class: "font-weight-bold blue-grey lighten-5",
            }
          );

          response.data.groupClasses.map((val) =>
            headersPrint.value.push({
              text: val.fechaClase,
              value: val.fechaClase,
              align: "center",
              sortable: false,
              class: "font-weight-bold blue-grey lighten-5",
            })
          );
          studentsAssistance.value = response.data.students;
        }
      } catch (error) {
        console.log(error);
      }
    };
    const selectAttendanceStudent = async (idGroup, idUser) => {
      try {
        let response = await axios.get(
          `attendances/selectAttendanceStudent/${idGroup}/${idUser}`
        );
        if (response.status == 200) {
          attendanceStudent.value = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const selectCourseStudents = async (idGroup) => {
      let url = `courses/selectCourseStudentsByIdGroup/${idGroup}/1`;
      loading.value = true;
      try {
        let response = await axios.get(url);
        if (response.status == 200) {
          students.value = response.data;
          loading.value = false;
        }
      } catch (error) {
        console.log(error);
        loading.value = false;
      }
    };

    const selectAssistancesByDate = async (year, month) => {
      try {
        let response = await axios.get("students/selectAssistancesByDate", {
          params: {
            idGroup: formGroup.value.idGroup,
            idStudent: studentData.value.idUsuario,
            year: year,
            month: month,
          },
        });
        if (response.status == 200) {
          loadingGrades.value = false;
          isOpened.value = true;
          events.value = response.data.assistances;
          console.log(response.data.assistances);
          events.value.map((value) => {
            value.start = value.start.substring(0, 10);
            value.end = value.end.substring(0, 10);
          });
        }
      } catch (error) {
        console.log(error);
        loadingGrades.value = false;
      }
    };

    const selectGroupBySemester = async (idSemester) => {
      try {
        let response = await axios.get(
          `groupTeachers/selectGroupAttendanceByIdSemester/${store.state.idDepartament}/${idSemester}`
        );
        if (response.status == 200) {
          groups.value = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const selectSemesterTeacher = async () => {
      try {
        let response = await axios.get(
          `semesters/selectSemesterTeacher/${store.state.idDepartament}`
        );
        if (response.status == 200) {
          semesters.value = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      selectSemesterTeacher();
    });

    //methods
    const print = () => {
      studentRef.value.generatePdf();
    };
    const downloadXlsx = () => {
      let wb = XLSX.utils.book_new();
      const data = XLSX.utils.json_to_sheet(studentsAssistance.value);
      XLSX.utils.book_append_sheet(wb, data, "Asistencia");

      XLSX.writeFile(wb, "ReporteAsistencia.xlsx");
    };
    //calendar methods
    const changeCalendar = ({ start, end }) => {
      end;
      titleCalendar.value = calendar.value.title.toUpperCase();
      selectAssistancesByDate(start.year, start.month);
      console.log(start);
      //console.log($refs.calendar);
    };

    const setToday = () => {
      focus.value = "";
    };

    const prev = () => {
      calendar.value.prev();
    };

    const next = () => {
      calendar.value.next();
    };

    //
    const saveStart = (date) => {
      startDate.value.save(date);
      /*formSearchReport.startDate = '';
            formSearchReport.endDate = '';
            delete detailsComponent.endDate;
            delete detailsComponent.startDate;
            detailsComponent = {
                ...detailsComponent,
                startDate: date    
            }*/
    };

    const saveEnd = (date) => {
      endDate.value.save(date);
      /*      formSearchReport.startDate = '';
            formSearchReport.endDate = '';
            delete detailsComponent.endDate;
            delete detailsComponent.startDate;
            detailsComponent = {
                ...detailsComponent,
                startDate: date    
            }*/
    };

    const changeGroup = (item) => {
      students.value = [];
      studentsAssistance.value = [];
      headersPrint.value = [];
      months.value = [];
      formGroup.value.month = "";
      formGroup.value.year = "";

      const obj = groups.value.find((group) => group.idGrupo === item);
      months.value = obj.months;
      drills.value = obj.cuotas;
      group.value.cuotas = obj.cuotas;
      group.value.nombreGrupo = obj.nombreGrupo;
    };

    const changeMonth = (item) => {
      students.value = [];
      studentsAssistance.value = [];
      headersPrint.value = [];

      const month = months.value.find((month) => month.value === item);
      formGroup.value.year = month.year;
      formGroup.value.dateString = month.text;
      startDate.value = month.date;
    };

    const dialogDownloadStop = () => {
      dialogDownload.value = false;
    };

    /*const printGrade = () => {
      dialogDownload.value = true;
      $refs.reportGrade.generatePdf();
    };*/

    const closeGrade = () => {
      dialogGrades.value = false;
      events.value = [];
      attendanceStudent.value = {};
      focus.value = "";
    };

    const viewGrades = (item) => {
      dialogGrades.value = true;

      studentData.value = {};
      studentData.value = Object.assign({}, item);
      loadingGrades.value = true;
      now.value = new Date(startDate.value);

      if (isOpened.value) {
        selectAssistancesByDate(
          now.value.getFullYear(),
          now.value.getMonth() + 1
        );
      }
      selectAttendanceStudent(formGroup.value.idGroup, item.idUsuario);
    };

    const findStudents = async () => {
      const valid = await formGradeSearch.value.validate();
      if (valid) {
        students.value = [];
        studentsAssistance.value = [];
        headersPrint.value = [];
        selectCourseStudents(formGroup.value.idGroup);
        const params = {
          idGroup: formGroup.value.idGroup,
          month: formGroup.value.month,
          year: formGroup.value.year,
        };
        selectStudentsAssistance(params);
      }
    };

    const groupFilter = (item, queryText) => {
      const textOne = item.nombreGrupo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    };

    const findGroup = (item) => {
      groups.value = [];
      formGroup.value.idGroup = "";
      formGroup.value.idSubject = "";
      students.value = [];
      studentsAssistance.value = [];
      headersPrint.value = [];
      drills.value = 0;

      selectGroupBySemester(item);
    };

    const semesterFilter = (item, queryText) => {
      const textOne = item.nombreSemestre.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    };

    /*const notification = (type, text) => {
      snackbar.value = true;
      colorSnackbar.value = type;
      textSnackbar.value = text;
    };*/

    const formatDate = (date) => {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    };

    //co
    watch(dialogGrades, (val) => {
      val || closeGrade();
    });

    watch(calendar, (val) => {
      titleCalendar.value = val.title.toUpperCase();
    });

    return {
      //properties
      user,
      studentRef,
      headersPrint,
      studentsAssistance,
      dialog,
      dialogDelete,
      search,
      group,
      dialogGrades,
      studentGrade,
      headersGrade,
      loadingGrades,
      dialogDownload,
      studentData,
      headers,
      students,
      semesters,
      groups,
      months,
      formGroup,
      menuStart,
      menuEnd,
      activePicker,
      semesterRules,
      groupRules,
      monthsRules,
      loading,
      loadingData,
      snackbar,
      colorSnackbar,
      textSnackbar,
      editedItem,
      items,
      turns,
      formGrade,
      drills,
      attendanceStudent,
      focus,
      events,
      titleCalendar,
      startDate,
      endDate,
      formGradeSearch,
      calendar,
      now,
      //methods
      changeCalendar,
      setToday,
      prev,
      next,
      saveStart,
      saveEnd,
      changeGroup,
      changeMonth,
      dialogDownloadStop,
      viewGrades,
      findStudents,
      groupFilter,
      findGroup,
      semesterFilter,
      formatDate,
      print,
      downloadXlsx,
    };
  },
};
</script>

<style scoped>
</style>