<template>
  <vue-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="false"
    :paginate-elements-by-height="1200"
    :pdf-quality="2"
    :manual-pagination="false"
    pdf-format="letter"
    pdf-orientation="landscape"
    pdf-content-width="800px"
    :html-to-pdf-options="htmlToPdfOptions"
    ref="html2Pdf"
  >
    <section v-cloak class="section" slot="pdf-content">
      <div class="page image-up" ref="imageForward">
        <div class="row" style="height: 320px"></div>
        <div class="row">
          <div class="col-12 pl-n2 mb-4">
            <div class="row justify-content-center">
              <img
                :src="user.avatar"
                height="310"
                class="circular--square pt-n4"
                alt="..."
              />
            </div>
          </div>
          <div class="col-12 mt-2 mb-4">
            <div class="row justify-content-center">
              <h1 class="font-weight-bold text-white">{{ user.nombres }}</h1>
            </div>
            <div class="row justify-content-center">
              <h1 class="font-weight-bold text-white">{{ user.apellidos }}</h1>
            </div>
            <div class="row justify-content-center">
              <h3 class="text-white">{{ "CI " + user.ci }}</h3>
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="row justify-content-center">
              <h1 class="text-white font-weight-bold">
                {{ group.nombreGrupo }}
              </h1>
            </div>
            <div class="row justify-content-center">
              <h2 class="text-white">{{ group.nombreTurno }}</h2>
            </div>
          </div>
        </div>
        <div class="row" style="height: 210px"></div>
      </div>
      <div class="page image-down" ref="imageBack">
        <!--img class="image-down" :src="require('@/assets/credentialUp.png')"/-->
        <div class="row" style="height: 250px"></div>
        <div class="row">
          <div class="col-12 px-2">
            <div class="container px-5">
              <h3 class="text-justify">
                "El portador de este credencial es estudiante del instituto por
                lo que se ruega colaborar, si requiere alguna informacion o
                descuento de pasajes en medios de servicio publico" Gracias...
              </h3>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="row justify-content-center">
              <vue-qr
                :text="user.qrCode"
                :size="490"
                :dotScale="1"
                :margin="10"
              ></vue-qr>
            </div>
          </div>
        </div>
        <div class="row" style="height: 105px">
          <div class="col-12 px-2">
            <div class="container px-5">
              <h4 class="text-center font-weight-bold">
                {{
                  "Valido hasta " +
                  (group.fechaFin != null
                    ? formatDateReport(group.fechaFin.substring(0, 10))
                    : "")
                }}
              </h4>
            </div>
          </div>
        </div>
        <div class="row" style="height: 210px"></div>
      </div>
    </section>
  </vue-html2pdf>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import vueQr from "vue-qr";
import { mapState } from "vuex";
import html2canvas from "html2canvas";
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    group: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VueHtml2pdf,
    vueQr,
  },
  data() {
    return {
      htmlToPdfOptions: {
        margin: [0, 0, 0, 0],

        filename: `Credential.pdf`,

        image: {
          type: "jpeg",
          quality: 1,
        },

        enableLinks: false,

        html2canvas: {
          scale: 3,
          useCORS: true,
        },

        jsPDF: {
          unit: "in",
          format: "letter",
          orientation: "portrait",
        },
      },
    };
  },
  computed: {
    ...mapState(["idDepartament"]),
  },
  created() {},
  watch: {
    user(newValue) {
      this.htmlToPdfOptions.filename = `${newValue.ci}Credential.pdf`;
    },
  },
  methods: {
    generatePdf() {
      this.htmlToPdfOptions.filename = `${this.user.ci}Credential.pdf`;
      this.$refs.html2Pdf.generatePdf();
    },

    async generateJpg() {
      const forward = this.$refs.imageForward;
      const back = this.$refs.imageBack;

      const canvasForward = await html2canvas(forward, {
        scale: 2,
        useCORS: true,
      });

      const canvasBack = await html2canvas(back, {
        scale: 2,
        useCORS: true,
      });

      // Convierte el canvas a imagen JPG
      const imageForward = canvasForward.toDataURL("image/jpeg", 1.0);
      const imageBack = canvasBack.toDataURL("image/jpeg", 1.0);

      // Descarga la imagen
      const linkForward = document.createElement("a");
      linkForward.href = imageForward;
      linkForward.download = `${this.user.ci}CredentialForward.jpg`;
      linkForward.click();

      const linkBack = document.createElement("a");
      linkBack.href = imageBack;
      linkBack.download = `${this.user.ci}CredentialBack.jpg`;
      linkBack.click();
    },
    formatDateReport(date) {
      const nameMonth = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      const [year, month, day] = date.split("-");
      day;
      return `${nameMonth[Number(month) - 1]} ${year}`;
    },
  },
};
</script>

<style scoped>
.image-up {
  content: "";
  background-image: url("../../assets/credentialUp.png");
  position: absolute;
  background-position: initial;
  background-size: 815px;
  top: 0px;
  right: 0px; /*322px;*/
  bottom: 0px; /*265px;*/
  left: 0px; /*323px;*/
}

.image-down {
  content: "";
  background-image: url("../../assets/credentialDown.png");
  position: absolute;
  background-size: 815px;
}
.page {
  width: 216mm;
  height: 280mm;
  margin: 0 0;
  page-break-after: always; /* Esta regla genera una nueva página después de cada div.page */
}
.pl,
.px-5 {
  padding-left: 4rem !important;
}
.pr,
.px-5 {
  padding-right: 4rem !important;
}
.text-justify {
  text-align: justify !important;
}
.circular--square {
  border-radius: 50%;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.section {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}

/*.section::before{
        content: "";
		background-image: 
				url('../../assets/credentialUp.png');
		position: absolute;
        background-position: initial;
        background-size: 815px;
		top: 0px;
		right: 0px;/*322px;*
		bottom: 0px; /*265px;*
		left: 0px;/*323px;*
    }*/
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.text-small {
  font-size: 13px;
}
.text-medium {
  font-size: 11px;
}
.text-tb-small {
  font-size: 10px;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.pl-n2 {
  padding-left: -0.5rem !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 1rem;
}
.text-center {
  text-align: center !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}
.text-right {
  text-align: right !important;
}
small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}
.p-0 {
  padding: 0 !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.text-white {
  color: #fff !important;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.text-white {
  color: #fff !important;
}
</style>