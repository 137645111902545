<template>
  <v-layout align-start>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-toolbar flat>
        <v-toolbar-title>Grupos </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
          dense
          outlined
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="#1486bd" dark @click="dialog = true">
          <span class="text-capitalize text-body">nuevo</span>
        </v-btn>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="py-0">
                      <p class="mb-2">Nombre</p>
                      <v-text-field
                        v-model="formGroup.nameGroup"
                        class="pt-0"
                        label="Nombre"
                        placeholder="Nombre"
                        single-line
                        dense
                        outlined
                        :rules="nameRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Fecha inicio</p>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted"
                            label="Fecha"
                            prepend-inner-icon="mdi-calendar"
                            single-line
                            dense
                            outlined
                            readonly
                            :rules="dateRules"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="formGroup.startDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Turno</p>
                      <v-select
                        v-model="formGroup.idTurn"
                        :items="turns"
                        item-text="nombreTurno"
                        item-value="idTurno"
                        label="Turno"
                        persistent-hint
                        dense
                        outlined
                        single-line
                        :rules="turnRules"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Cuotas</p>
                      <v-text-field
                        v-model="formGroup.share"
                        class="pt-0"
                        label="Cuotas"
                        placeholder="Cuotas"
                        single-line
                        dense
                        outlined
                        :rules="shareRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Costo</p>
                      <v-text-field
                        v-model="formGroup.cost"
                        class="pt-0"
                        label="Costo"
                        placeholder="Costo"
                        single-line
                        dense
                        outlined
                        :rules="costRules"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                :disabled="loadingData"
                @click="close"
              >
                <span class="text-capitalize">Cancelar</span>
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                :loading="loadingData"
                @click="saveGroup"
              >
                <span class="text-capitalize">Guardar</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <p style="color: #1874bf" class="text-h6 text-center pt-4">
              Informe de semestre
            </p>
            <v-card-text v-if="user.idRol == 1" class="text-center">
              {{
                `¿Esta seguro de ${
                  editedItem.estado ? "desactivar" : "activar"
                } el grupo ${editedItem.nombreGrupo}?`
              }}
            </v-card-text>
            <v-card-text v-else class="text-center">
              ¿Esta seguro de eliminar el grupo {{ editedItem.nombreGrupo }}?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
              <v-btn dark color="blue darken-1" @click="deleteItemConfirm"
                >Si</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-container v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>
      <template v-if="groups.length > 0">
        <v-data-table
          :headers="user.idRol == 1 ? headersAdmin : headers"
          :items="groups"
          :search="search"
          item-key="idGrupo"
          class="elevation-1 font-weight-light caption td-click"
          @click:row="clickRow"
          fixed-header
        >
          <template v-slot:[`item.fechaInicio`]="{ item }">
            {{ formatDate(item.fechaInicio.substring(0, 10)) }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            {{ item.estado ? "Activo" : "Inactivo" }}
          </template>
          <template v-slot:[`item.accion`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mr-2"
                  @click.stop="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dense>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="amber"
                  class="mr-2"
                  @click.stop="assignSubject(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-book-plus</v-icon>
                </v-btn>
              </template>
              <span>Asignar Materia</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="user.idRol == 1"
                  icon
                  :color="item.estado ? 'error' : 'success'"
                  class="mr-2"
                  @click.stop="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-if="user.idRol == 1" dense>{{
                    item.estado ? "mdi-cancel" : "mdi-check"
                  }}</v-icon>
                  <v-icon v-else dense>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span v-if="user.idRol == 1">{{
                item.estado ? "Desactivar" : "Activar"
              }}</span>
              <span v-else>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <v-card elevation="0" outlined v-if="loading == false">
          <v-card-text class="text-center pb-0"
            >No existen Grupos Registrados.</v-card-text
          >
          <v-card-text class="text-center pt-0">
            <v-btn
              color="blue darken-1"
              dark
              small
              class="my-2 px-2"
              tile
              elevation="0"
              @click="selectGroups"
              >Volver a Intentar</v-btn
            >
          </v-card-text>
        </v-card>
      </template>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
      <v-dialog v-model="dialogInfo" max-width="400px">
        <v-card>
          <p style="color: #1874bf" class="text-h6 text-center pt-4">
            Informe de Grupo
          </p>
          <v-card-text class="text-center">
            No se puede ver los registros debido a que el grupo esta
            desactivado, para continuar debe activar el grupo.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="blue darken-1" @click="dialogInfo = false"
              >Aceptar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAssignSubject" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ "Materias Asignadas" }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formSubject">
                <v-row>
                  <v-col cols="12" sm="5" md="5" class="py-0">
                    <p class="mb-2">Materias</p>
                    <v-autocomplete
                      v-model="idSubject"
                      :items="subjects"
                      :filter="customFilter"
                      outlined
                      dense
                      item-text="nombreMateria"
                      item-value="idMateria"
                      :rules="subjectRules"
                      no-data-text="No hay materias disponibles"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="5" md="5" class="py-0">
                    <p class="mb-2">Profesor</p>
                    <v-autocomplete
                      v-model="idTeacher"
                      :items="teachers"
                      :filter="teacherFilter"
                      outlined
                      dense
                      item-text="nombreCompleto"
                      item-value="idUsuario"
                      :rules="teacherRules"
                      no-data-text="No existe el profesor"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                    class="py-0 d-flex align-center"
                  >
                    <v-btn
                      color="blue darken-1"
                      dark
                      :loading="loadingData"
                      @click="saveAssignSubject"
                    >
                      <span class="text-capitalize">Asignar</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-data-table
                :headers="headersSubjects"
                :items="assignedSubjects"
                item-key="idMateria"
                class="elevation-1 font-weight-light caption mt-2"
                fixed-header
              >
                <template v-slot:[`item.accion`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        :color="item.estado ? 'error' : 'success'"
                        class="mr-2"
                        @click.stop="deleteSubject(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeSubjectTeacher">
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Nombre",
          value: "nombreGrupo",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Turno",
          value: "nombreTurno",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Cuotas",
          value: "cuotas",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Fecha Inicio",
          value: "fechaInicio",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      headersAdmin: [
        {
          text: "Nombre",
          value: "nombreGrupo",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Turno",
          value: "nombreTurno",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Cuotas",
          value: "cuotas",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Fecha Inicio",
          value: "fechaInicio",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Estado",
          value: "estado",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      groups: [],
      editedIndex: -1,
      formGroup: {
        idGroup: "",
        nameGroup: "",
        share: "",
        startDate: new Date().toISOString().substring(0, 10),
        creationDate: new Date().toISOString().substring(0, 10),
        cost: "",
        previousAmount: "",
        idSemester: this.$route.params.idSemester,
        idTurn: "",
      },
      nameGroup: "",
      idGroup: "",
      creationDate: new Date().toISOString().substring(0, 10),
      nameRules: [
        (value) => !!value || "Ingrese un Grupo",
        (value) =>
          /^[A-Z]{2,6}-\d{1,3}$/i.test(value) ||
          "Debe ingresar un formato correcto",
      ],
      dateRules: [(value) => !!value || "Ingrese una fecha"],
      turnRules: [(value) => !!value || "Seleccione un turno"],
      shareRules: [
        (value) => !!value || "Ingrese la cantidad de cuotas",
        (value) => /^[0-9]+$/.test(value) || "Solo debe ingresar numeros",
      ],
      costRules: [
        (value) => !!value || "Ingrese el costo del grupo",
        (value) => /^[0-9]+$/.test(value) || "Solo debe ingresar numeros",
      ],
      loading: false,
      loadingData: false,
      snackbar: false,
      colorSnackbar: "",
      textSnackbar: "",
      editedItem: {},
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      items: [
        {
          text: "Semestre",
          disabled: false,
          href: "/semester",
        },
        {
          text: "Grupos",
          disabled: true,
          href: "/group/" + this.$route.params.idSemester,
        },
      ],
      menu2: false,
      turns: [],
      dialogInfo: false,
      dialogAssignSubject: false,
      subjects: [],
      assignedSubjects: [],
      idSubject: "",
      headersSubjects: [
        {
          text: "Materia",
          value: "nombreMateria",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Profesor Asignado",
          value: "docente",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      subjectRules: [(value) => !!value || "Debe ingresar una materia"],
      idTeacher: "",
      teachers: [],
      teacherRules: [(value) => !!value || "Debe ingresar un profesor"],
      search: "",
    };
  },
  computed: {
    ...mapState(["user", "idDepartament"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo grupo" : "Editar grupo";
    },
    computedDateFormatted() {
      return this.formatDate(this.formGroup.startDate);
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogAssignSubject(val) {
      val || this.closeSubjectTeacher();
    },
  },
  created() {
    this.selectGroups();
    this.selectTurn();
  },
  methods: {
    async deleteSubjectGroup(data) {
      try {
        let response = await axios.put("subjects/deleteSubjectGroup", data);
        if (response.status == 200) {
          this.notification("warning", response.data["msg"]);
          this.selectSubjectGroupByIdGroup();
          this.selectSubjectGroup();
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    deleteSubject(item) {
      let data = {
        idGroupSubject: item.idGrupoMateria,
      };
      this.deleteSubjectGroup(data);
    },
    async insertSubjectGroup(data) {
      try {
        let response = await axios.post("subjects/insertSubjectGroup", data);
        if (response.status == 200) {
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.selectSubjectGroupByIdGroup();
          this.selectSubjectGroup();
          this.selectTeachers();
          this.idTeacher = "";
          this.idSubject = "";
          this.$refs.formSubject.resetValidation();
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    saveAssignSubject() {
      if (this.$refs.formSubject.validate()) {
        this.loadingData = true;
        let data = {
          idGroup: this.editedItem.idGrupo,
          idSubject: this.idSubject,
          idTeacher: this.idTeacher,
        };
        this.insertSubjectGroup(data);
      }
    },
    customFilter(item, queryText) {
      const textOne = item.nombreMateria.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    teacherFilter(item, queryText) {
      const textOne = item.nombreCompleto.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    async selectSubjectGroupByIdGroup() {
      try {
        let response = await axios.get(
          `subjects/selectSubjectGroupByIdGroup/${this.editedItem.idGrupo}/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.subjects = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectSubjectGroup() {
      try {
        let response = await axios.get(
          `subjects/selectSubjectGroup/${this.editedItem.idGrupo}/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.assignedSubjects = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    assignSubject(item) {
      this.dialogAssignSubject = true;
      this.editedItem = Object.assign({}, item);
      this.selectSubjectGroupByIdGroup();
      this.selectSubjectGroup();
      this.selectTeachers();
    },
    clickRow(item) {
      if (!item.estado) {
        this.dialogInfo = true;
        return;
      }
      this.$router.push(
        `/semester/${this.$route.params.idSemester}/group/${item.idGrupo}`
      );
    },
    async deleteGroup(data) {
      try {
        let response = await axios.put("groups/deleteGroup", data);
        if (response.status == 200) {
          this.closeDelete();
          this.notification(
            "warning",
            this.user.idRol == 1 ? response.data["msg"] : "Se elimino el grupo"
          );
          this.selectGroups();
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async insertGroup(data) {
      try {
        let response = await axios.post("groups/insertGroup", data);
        if (response.status == 200) {
          if (response.data.type == 0) {
            this.loadingData = false;
            this.notification("success", response.data["msg"]);
          } else {
            this.close();
            this.loadingData = false;
            this.notification("success", response.data["msg"]);
            this.selectGroups();
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async updateGroup(data) {
      try {
        let response = await axios.put("groups/updateGroup", data);
        if (response.status == 200) {
          if (response.data.type == 0) {
            this.loadingData = false;
            this.notification("success", response.data["msg"]);
          } else {
            this.close();
            this.loadingData = false;
            this.notification("success", response.data["msg"]);
            this.selectGroups();
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    saveGroup() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          //modificar
          let nameGroupArray = this.formGroup.nameGroup.split("-");
          let number = nameGroupArray[1];
          let code = nameGroupArray[0];

          let data = {
            idGroup: this.formGroup.idGroup,
            nameGroup: code.toUpperCase() + " - " + number,
            share: this.formGroup.share,
            startDate: this.formGroup.startDate,
            cost: this.formGroup.cost,
            idTurn: this.formGroup.idTurn,
            idSemester: this.formGroup.idSemester,
            numberGroup: number,
            codeGroup: code.toUpperCase(),
            previousAmount: this.formGroup.previousAmount,
          };
          this.loadingData = true;
          this.updateGroup(data);
        } else {
          let nameGroupArray = this.formGroup.nameGroup.split("-");
          let number = nameGroupArray[1];
          let code = nameGroupArray[0];

          let data = {
            nameGroup: code.toUpperCase() + " - " + number,
            share: this.formGroup.share,
            startDate: this.formGroup.startDate,
            creationDate: this.formGroup.creationDate,
            cost: this.formGroup.cost,
            idSemester: this.formGroup.idSemester,
            idTurn: this.formGroup.idTurn,
            numberGroup: number,
            codeGroup: code.toUpperCase(),
          };

          this.loadingData = true;
          this.insertGroup(data);
        }
      }
    },

    async selectGroups() {
      let url =
        this.user.idRol == 1
          ? `groups/selectGroups/${this.$route.params.idSemester}/null`
          : `groups/selectGroups/${this.$route.params.idSemester}/1`;
      try {
        this.loading = true;
        let response = await axios.get(url);
        if (response.status == 200) {
          this.groups = response.data;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async selectTurn() {
      try {
        let response = await axios.get(
          `turns/selectTurns/1/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.turns = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectTeachers() {
      try {
        let response = await axios.get(
          `users/selectTeachers/${this.idDepartament}/1`
        );
        if (response.status == 200) {
          this.teachers = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    notification(type, text) {
      this.snackbar = true;
      this.colorSnackbar = type;
      this.textSnackbar = text;
    },

    editItem(item) {
      this.editedIndex = 1;
      this.editedItem = Object.assign({}, item);
      let name = this.editedItem.nombreGrupo.split("-");
      this.formGroup.idGroup = this.editedItem.idGrupo;
      this.formGroup.nameGroup = name[0].trim() + "-" + name[1].trim();
      this.formGroup.share = this.editedItem.cuotas;
      this.formGroup.cost = this.editedItem.costo;
      this.formGroup.previousAmount = this.editedItem.costo;
      this.formGroup.idTurn = this.editedItem.idTurno;
      this.formGroup.startDate = this.editedItem.fechaInicio.substring(0, 10);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let data = {
        idGroup: this.editedItem.idGrupo,
        state: this.editedItem.estado ? 0 : 1,
      };
      this.deleteGroup(data);
    },
    closeSubjectTeacher() {
      this.dialogAssignSubject = false;
      this.idSubject = "";
      this.editedItem = "";
      this.assignedSubjects = [];
      this.$refs.formSubject.resetValidation();
    },

    close() {
      this.nameGroup = "";
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
      this.formGroup.idGroup = "";
      this.formGroup.nameGroup = "";
      this.formGroup.share = "";
      this.formGroup.cost = "";
      this.formGroup.previousAmount = "";
      this.formGroup.idTurn = "";
      this.formGroup.startDate = new Date().toISOString().substring(0, 10);
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editItem = "";
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
.td-click td {
  cursor: pointer;
}
</style>