<template>
  <v-layout align-start>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-toolbar flat>
        <v-toolbar-title>Profesores</v-toolbar-title>
        <v-switch
          v-model="active"
          class="ml-4"
          :label="messageSwitch"
          @change="changeState"
          hide-details
        ></v-switch>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
          dense
          outlined
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="dialog = true">
          <span class="text-capitalize text-body">nuevo</span>
        </v-btn>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Nombres</p>
                      <v-text-field
                        v-model="formTeacher.names"
                        class="pt-0"
                        label="Nombres"
                        placeholder="Nombres"
                        single-line
                        dense
                        outlined
                        autocomplete="null"
                        :rules="nameRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Apellidos</p>
                      <v-text-field
                        v-model="formTeacher.surnames"
                        class="pt-0"
                        label="Apellidos"
                        placeholder="Apellidos"
                        single-line
                        dense
                        outlined
                        autocomplete="null"
                        :rules="surnameRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Ci</p>
                      <v-text-field
                        v-model="formTeacher.ci"
                        class="pt-0"
                        label="Ci"
                        placeholder="Ci"
                        single-line
                        dense
                        outlined
                        autocomplete="null"
                        :rules="ciRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Telefono</p>
                      <v-text-field
                        v-model="formTeacher.phone"
                        class="pt-0"
                        label="Telefono"
                        placeholder="Telefono"
                        single-line
                        dense
                        outlined
                        autocomplete="null"
                        :rules="phoneRules"
                      >
                      </v-text-field>
                    </v-col>
                    <template v-if="user.idRol == 1">
                      <v-col cols="12" sm="6" md="6" class="py-0">
                        <p class="mb-2">Usuario</p>
                        <v-text-field
                          v-model="formTeacher.user"
                          class="pt-0"
                          label="Usuario"
                          placeholder="Usuario"
                          single-line
                          dense
                          outlined
                          autocomplete="null"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="py-0">
                        <p class="mb-2">Contraseña</p>
                        <v-text-field
                          v-model="formTeacher.password"
                          class="pt-0"
                          :append-icon="
                            passwordShow ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          :type="passwordShow ? 'text' : 'password'"
                          label="Password"
                          placeholder="Password"
                          @click:append="passwordShow = !passwordShow"
                          single-line
                          dense
                          outlined
                          clearable
                          autocomplete="null"
                        >
                        </v-text-field>
                      </v-col>
                    </template>
                    <v-col cols="12" sm="12" md="12" class="py-0">
                      <p class="mb-2">Email</p>
                      <v-text-field
                        v-model="formTeacher.email"
                        class="pt-0"
                        label="Email"
                        placeholder="Email"
                        single-line
                        dense
                        outlined
                        autocomplete="null"
                        :rules="emailRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      class="py-0"
                      v-if="user.idRol == 1"
                    >
                      <p class="mb-2">Sucursal</p>
                      <v-select
                        v-model="formTeacher.idBranchoffice"
                        :items="branchoffices"
                        item-text="nombreSucursal"
                        item-value="idSucursal"
                        label="Sucursal"
                        persistent-hint
                        dense
                        outlined
                        single-line
                        :rules="branchofficeRules"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Rol</p>
                      <v-select
                        v-model="formTeacher.idRole"
                        :items="roles"
                        item-text="nombreRol"
                        item-value="idRol"
                        label="Rol"
                        persistent-hint
                        dense
                        outlined
                        single-line
                        :rules="roleRules"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                :disabled="loadingData"
                @click="close"
              >
                <span class="text-capitalize">Cancelar</span>
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                :loading="loadingData"
                @click="saveTeacher"
              >
                <span class="text-capitalize">Guardar</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <p style="color: #1874bf" class="text-h6 text-center pt-4">
              Informe de semestre
            </p>
            <v-card-text v-if="user.idRol == 1" class="text-center">
              {{
                `¿Esta seguro de ${
                  editedItem.estado ? "desactivar" : "activar"
                } el profesor ${editedItem.nombres}?`
              }}
            </v-card-text>
            <v-card-text v-else class="text-center">
              ¿Esta seguro de eliminar el profesor {{ editedItem.nombres }}?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
              <v-btn dark color="blue darken-1" @click="deleteItemConfirm"
                >Si</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-container v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>
      <template v-if="teachers.length > 0">
        <v-data-table
          :headers="user.idRol == 1 ? headersAdmin : headers"
          :items="teachers"
          item-key="idUsuario"
          class="elevation-1 font-weight-light caption td-click"
          :search="search"
          @click:row="clickRow"
          fixed-header
        >
          <template v-slot:[`item.password`]="{ item }">
            {{ show ? item.password : "......." }}
            <v-btn small icon @click="showPassword(item)">
              <v-icon small>{{ show ? "mdi-eye-off" : "mdi-eye" }}</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            {{ item.estado ? "Activo" : "Inactivo" }}
          </template>
          <template v-slot:[`item.accion`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mr-2"
                  @click.stop="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="amber"
                  class="mr-2"
                  @click.stop="assignSubject(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-book-plus</v-icon>
                </v-btn>
              </template>
              <span>Asignar Materia</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="user.idRol == 1"
                  icon
                  color="warning"
                  class="mr-2"
                  @click.stop="changeCredentials(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-account-key</v-icon>
                </v-btn>
              </template>
              <span>Cambiar Credenciales</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :color="item.estado ? 'error' : 'success'"
                  class="mr-2"
                  @click.stop="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-if="user.idRol == 1">{{
                    item.estado ? "mdi-cancel" : "mdi-check"
                  }}</v-icon>
                  <v-icon v-else>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span v-if="user.idRol == 1">{{
                item.estado ? "Desactivar" : "Activar"
              }}</span>
              <span v-else>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <v-card elevation="0" outlined v-if="loading == false">
          <v-card-text class="text-center pb-0"
            >No existen Profesores Registrados.</v-card-text
          >
          <v-card-text class="text-center pt-0"
            ><v-btn
              color="blue darken-1"
              dark
              small
              class="my-2 px-2"
              tile
              elevation="0"
              @click="selectTeachers"
              >Volver a Intentar</v-btn
            ></v-card-text
          >
        </v-card>
      </template>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
      <v-dialog v-model="dialogAssignSubject" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ "Materias Asignadas" }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formSubject">
                <v-row>
                  <v-col cols="12" sm="8" md="8" class="py-0">
                    <p class="mb-2">Materias</p>
                    <v-autocomplete
                      v-model="idSubject"
                      :items="subjects"
                      :filter="customFilter"
                      outlined
                      dense
                      item-text="nombreMateria"
                      item-value="idMateria"
                      :rules="subjectRules"
                      no-data-text="No hay materias disponibles"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0 d-flex align-center"
                  >
                    <v-btn
                      color="blue darken-1"
                      dark
                      :loading="loadingData"
                      @click="saveAssignSubject"
                    >
                      <span class="text-capitalize">Asignar</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-data-table
                :headers="headersSubjects"
                :items="assignedSubjects"
                item-key="idMateria"
                class="elevation-1 font-weight-light caption mt-2"
                fixed-header
              >
                <template v-slot:[`item.accion`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        :color="item.estado ? 'error' : 'success'"
                        class="mr-2"
                        @click.stop="deleteSubject(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeSubjectTeacher">
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogTransfer" max-width="400px">
        <v-card>
          <p style="color: #1874bf" class="text-h6 text-center pt-4">
            Informe de Transferencia
          </p>
          <v-card-text class="text-center">
            {{ message }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeTransfer">No</v-btn>
            <v-btn dark color="blue darken-1" @click="transferConfirm"
              >Si</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <CredentialChangeUser
        ref="credentialChange"
        @notification="notificationChange"
        @deleteUserSelected="deleteUserSelected"
        :user="userSelected"
      />
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import CredentialChangeUser from "@/components/credential/credential-change-user-component.vue";
export default {
  components: {
    CredentialChangeUser,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Ci",
          value: "ci",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Apellidos",
          value: "apellidos",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombres",
          value: "nombres",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Tipo",
          value: "nombreRol",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        //{text: "Usuario", value: "usuario", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
        //{text: "Password", value: "password", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
        {
          text: "Sucursal",
          value: "nombreSucursal",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      headersAdmin: [
        {
          text: "Ci",
          value: "ci",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Apellidos",
          value: "apellidos",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombres",
          value: "nombres",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Tipo",
          value: "nombreRol",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        //{text: "Usuario", value: "usuario", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
        //{text: "Password", value: "password", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
        {
          text: "Sucursal",
          value: "nombreSucursal",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Estado",
          value: "estado",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      teachers: [],
      editedIndex: -1,
      formTeacher: {
        idTeacher: "",
        names: "",
        surnames: "",
        ci: "",
        email: "",
        phone: "",
        idBranchoffice: "",
        idRole: "",
        creationDate: new Date().toISOString().substring(0, 10),
        user: "",
        password: "",
        passwordBefore: "",
      },
      nameRules: [(value) => !!value || "Ingrese nombres"],
      surnameRules: [(value) => !!value || "Ingrese apellidos"],
      ciRules: [(value) => !!value || "Ingrese un ci"],
      emailRules: [
        (value) => !!value || "Ingrese un email",
        (value) => /.+@.+\..+/.test(value) || "Debe ingresar un email correcto",
      ],
      phoneRules: [
        (value) => !!value || "Ingrese un numero de telefono",
        (value) => /^[0-9]+$/.test(value) || "Debe ingresar un telefono valido",
      ],
      branchofficeRules: [(value) => !!value || "Seleccione una sucursal"],
      roleRules: [(value) => !!value || "Seleccione un rol"],
      loading: false,
      loadingData: false,
      snackbar: false,
      colorSnackbar: "",
      textSnackbar: "",
      editedItem: {},
      defaultItem: {},
      items: [
        {
          text: "Profesores",
          disabled: false,
          href: "/teacher",
        },
      ],
      menu2: false,
      branchoffices: [],
      roles: [],
      show: false,
      dialogAssignSubject: false,
      subjects: [],
      idSubject: "",
      assignedSubjects: [],
      headersSubjects: [
        {
          text: "Nombre",
          value: "nombreMateria",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Codigo",
          value: "codigoMateria",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      subjectRules: [(value) => !!value || "Debe ingresar una materia"],
      passwordShow: false,
      //transferStudent
      dialogTransfer: false,
      userData: {},
      message: "",
      idBranchofficeTransfer: "",
      userSelected: {},
      search: "",
      active: true,
    };
  },
  computed: {
    ...mapState(["user", "idDepartament"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Profesor" : "Editar Profesor";
    },
    messageSwitch() {
      return this.active ? "Activos" : "Inactivos";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogAssignSubject(val) {
      val || this.closeSubjectTeacher();
    },
  },
  created() {
    this.selectTeachers();
    this.selectBranchoffices();
    this.selectRoles();
  },
  methods: {
    ...mapMutations(["setStudentData"]),
    changeState() {
      this.selectTeachers();
    },
    //changeCredentials
    notificationChange(response) {
      this.notification(response.type, response.text);
      this.selectTeachers();
    },
    deleteUserSelected() {
      this.userSelected = {};
    },
    changeCredentials(item) {
      this.userSelected = item;
      this.$refs.credentialChange.openDialog();
    },
    //transferTeacher
    async updateTransferUser(data) {
      try {
        let response = await axios.put("users/updateTransferUser", data);
        if (response.status == 200) {
          this.closeTransfer();
          this.notification("success", response.data["msg"]);
          this.selectTeachers();
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    transferConfirm() {
      let data = {
        idBranchoffice: this.idBranchofficeTransfer,
        idUser: this.userData.idUsuario,
      };

      this.updateTransferUser(data);
    },
    closeTransfer() {
      this.dialogTransfer = false;
      this.userData = {};
      this.message = "";
      this.idBranchofficeTransfer = "";
    },
    //otherModule
    async deleteSubjectTeacher(data) {
      try {
        let response = await axios.put("subjects/deleteSubjectTeacher", data);
        if (response.status == 200) {
          this.notification("warning", response.data["msg"]);
          this.selectSubjects();
          this.selectSubjectTeacher();
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async insertSubjectTeacher(data) {
      try {
        let response = await axios.post("subjects/insertSubjectTeacher", data);
        if (response.status == 200) {
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.selectSubjects();
          this.selectSubjectTeacher();
          this.idSubject = "";
          this.$refs.formSubject.resetValidation();
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    saveAssignSubject() {
      if (this.$refs.formSubject.validate()) {
        this.loadingData = true;
        let data = {
          idTeacher: this.editedItem.idUsuario,
          idSubject: this.idSubject,
        };
        this.insertSubjectTeacher(data);
      }
    },
    customFilter(item, queryText) {
      const textOne = item.nombreMateria.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    assignSubject(item) {
      this.dialogAssignSubject = true;
      this.editedItem = Object.assign({}, item);
      this.selectSubjects();
      this.selectSubjectTeacher();
      //console.log(item);
    },
    clickRow(item) {
      //console.log(item);
      this.setStudentData(null);
      this.$router.push(`/teacher/${item.idUsuario}/schedule`);
    },
    async deleteUser(data) {
      try {
        let response = await axios.put("users/deleteUser", data);
        if (response.status == 200) {
          this.closeDelete();
          this.notification(
            "warning",
            this.user.idRol == 1
              ? response.data["msg"]
              : "Se elimino el estudiante"
          );
          this.selectTeachers();
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async insertTeacher(data) {
      try {
        let response = await axios.post("users/insertTeacher", data);
        if (response.status == 200) {
          if (
            response.data.type == 0 ||
            response.data.type == 1 ||
            response.data.type == 2
          ) {
            this.close();
            this.loadingData = false;
            this.notification("success", response.data["msg"]);
            this.selectTeachers();
          } else {
            this.idBranchofficeTransfer = this.formTeacher.idBranchoffice;
            this.close();
            this.loadingData = false;
            this.userData = response.data.data;
            this.message = response.data.msg;
            this.dialogTransfer = true;
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async updateTeacher(data) {
      try {
        let response = await axios.put("users/updateTeacher", data);
        if (response.status == 200) {
          this.close();
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.selectTeachers();
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    saveTeacher() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          //modificar
          let data = {
            names: this.formTeacher.names.toUpperCase().trim(),
            surnames: this.formTeacher.surnames.toUpperCase().trim(),
            ci: this.formTeacher.ci.trim(),
            email: this.formTeacher.email.trim(),
            phone: this.formTeacher.phone.trim(),
            idBranchoffice:
              this.user.idRol == 1
                ? this.formTeacher.idBranchoffice
                : this.user.idSucursal,
            idRole: this.formTeacher.idRole,
            idTeacher: this.formTeacher.idTeacher,
            user: this.formTeacher.user.trim(),
            password: this.formTeacher.password.trim(),
            passwordBefore: this.formTeacher.passwordBefore.trim(),
          };

          this.loadingData = true;
          this.updateTeacher(data);
        } else {
          let data = {
            names: this.formTeacher.names.toUpperCase().trim(),
            surnames: this.formTeacher.surnames.toUpperCase().trim(),
            ci: this.formTeacher.ci.trim(),
            email: this.formTeacher.email.trim(),
            phone: this.formTeacher.phone.trim(),
            idBranchoffice:
              this.user.idRol == 1
                ? this.formTeacher.idBranchoffice
                : this.user.idSucursal,
            idRole: this.formTeacher.idRole,
            user: this.user.idRol == 1 ? this.formTeacher.user.trim() : null,
            pass:
              this.user.idRol == 1 ? this.formTeacher.password.trim() : null,
          };
          this.loadingData = true;

          this.insertTeacher(data);
        }
      }
    },

    async selectTeachers() {
      /*let url =
        this.user.idRol == 1
          ? `users/selectTeachers/${this.idDepartament}/null`
          : `users/selectTeachers/${this.idDepartament}/1`;*/
      this.loading = true;
      this.teachers = [];
      try {
        let response = await axios.get(
          `users/selectTeachers/${this.idDepartament}/${this.active}`
        );
        if (response.status == 200) {
          this.teachers = response.data;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async selectBranchoffices() {
      let url = `branchoffices/selectBranchoffices/${this.idDepartament}/1`;
      try {
        let response = await axios.get(url);
        if (response.status == 200) {
          this.branchoffices = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectRoles() {
      try {
        let response = await axios.get("roles/selectTeacherRoles");
        if (response.status == 200) {
          this.roles = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectSubjects() {
      try {
        let response = await axios.get(
          `subjects/selectSubjectByIdTeacher/${this.editedItem.idUsuario}/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.subjects = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectSubjectTeacher() {
      try {
        let response = await axios.get(
          `subjects/selectSubjectTeacher/${this.editedItem.idUsuario}/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.assignedSubjects = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    notification(type, text) {
      this.snackbar = true;
      this.colorSnackbar = type;
      this.textSnackbar = text;
    },

    editItem(item) {
      this.editedIndex = 1;
      this.editedItem = Object.assign({}, item);
      this.formTeacher.idTeacher = this.editedItem.idUsuario;
      this.formTeacher.names = this.editedItem.nombres;
      this.formTeacher.surnames = this.editedItem.apellidos;
      this.formTeacher.ci = this.editedItem.ci;
      this.formTeacher.email = this.editedItem.email;
      this.formTeacher.phone = this.editedItem.telefono;
      this.formTeacher.idBranchoffice = this.editedItem.idSucursal;
      this.formTeacher.idRole = this.editedItem.idRol;
      this.formTeacher.user = this.editedItem.usuario;
      this.formTeacher.password = this.editedItem.password;
      this.formTeacher.passwordBefore = this.editedItem.password;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteSubject(item) {
      let data = {
        idSubjectTeacher: item.idDocenteMateria,
      };
      this.deleteSubjectTeacher(data);
    },

    deleteItemConfirm() {
      let data = {
        idUser: this.editedItem.idUsuario,
        state: this.editedItem.estado ? 0 : 1,
      };
      this.deleteUser(data);
    },

    close() {
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
      this.formTeacher.idTeacher = "";
      this.formTeacher.names = "";
      this.formTeacher.surnames = "";
      this.formTeacher.ci = "";
      this.formTeacher.email = "";
      this.formTeacher.phone = "";
      this.formTeacher.idBranchoffice = "";
      this.formTeacher.idRole = "";
      this.formTeacher.user = "";
      this.formTeacher.password = "";
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = "";
    },

    closeSubjectTeacher() {
      this.dialogAssignSubject = false;
      this.idSubject = "";
      this.editedItem = "";
      this.assignedSubjects = [];
      this.$refs.formSubject.resetValidation();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
.td-click td {
  cursor: pointer;
}
</style>