import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import { createPinia, PiniaVuePlugin } from "pinia";

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

//axios.defaults.baseURL = "http://192.168.77.187:4001/api/";
axios.defaults.baseURL = "https://api.isaacnewtonbolivia.com/api/";

new Vue({
	router,
	store,
	pinia,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
